<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { balanceService } from '@exchange/libs/balances/service/src';
import { modalVariant } from '@exchange/libs/modals/src';
import { useInitTransaction } from '@exchange/libs/transactions/funds/service/src';
import { AccountIsNotMain, CoinSelect, FetchBitpandaUser, UserBlocked, UserNotVerified, TransactionModal } from '@exchange/libs/transactions/shared/src';

const WithdrawCrypto = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-withdraw-crypto' */ './WithdrawCrypto.vue'));
const WithdrawFiat = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-withdraw-fiat' */ './WithdrawFiat.vue'));

const router = useRouter();
const route = useRoute();

const { list: balances } = balanceService;

const variant = modalVariant.dark;

const {
  currenciesToShow,
  defaultFiatCurrency,
  filterCurrencyList,
  getCurrencyFromRoute,
  isBlocked,
  isVerified,
  isMainAccount,
  isRetailWithMissingAml,
  resetSelectedCurrency,
  selectedCurrency,
  selectedCurrencyIsCrypto,
  verification,
  search,
} = useInitTransaction();

getCurrencyFromRoute(route, router);
</script>

<template>
  <transaction-modal
    class="withdraw-modal"
    name="withdraw"
    :variant="variant"
    :title="$t('modules.transactions.withdraw.title')"
  >
    <template #content="{ clear, searchClass, coinSelectClass, contentClass }">
      <fetch-bitpanda-user :variant="variant">
        <div
          class="deposit-modal__search"
          :class="searchClass"
        >
          <ot-search
            v-model:value="search"
            :variant="variant"
            :full-width="true"
            :on-search-cb="filterCurrencyList"
            testid="currency-search"
            @input-click="resetSelectedCurrency"
          />
        </div>
        <account-is-not-main
          v-if="!isMainAccount"
          operation="withdraw"
          :variant="variant"
          :class="contentClass"
          @close="clear"
        />
        <coin-select
          v-else
          v-model:selectedCurrency="selectedCurrency"
          type="withdraw"
          :variant="variant"
          :class="coinSelectClass"
          :currencies="currenciesToShow"
          :default-fiat-currency="defaultFiatCurrency"
          :balances="balances"
        />
        <ot-transition-collapse :duration="{ enter: 500, leave: 0 }">
          <component
            :is="selectedCurrencyIsCrypto ? WithdrawCrypto : WithdrawFiat"
            v-if="selectedCurrency && !isBlocked && isVerified && !isRetailWithMissingAml"
            :key="selectedCurrency.id"
            :variant="variant"
            :currency="selectedCurrency"
            :class="contentClass"
            @close="clear"
          />
          <user-blocked
            v-else-if="selectedCurrency && isBlocked"
            :class="contentClass"
            :variant="variant"
            @close="clear"
          />
          <user-not-verified
            v-else-if="selectedCurrency && (!isVerified || isRetailWithMissingAml)"
            :variant="variant"
            :verification="verification"
            :currency-symbol="selectedCurrency.id"
            :class="contentClass"
            text-key="modules.transactions.verification.commonText"
            link-text-key="modules.transactions.verification.button"
          />
        </ot-transition-collapse>
      </fetch-bitpanda-user>
    </template>
  </transaction-modal>
</template>
